/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */

/* ========================================
    global
        大見出し
======================================== */
.section-title {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    overflow: hidden;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 4px;
        z-index: -1;
    }

    &:before {
        background: repeating-linear-gradient(90deg, $C_WHITE 0, $C_WHITE 10px, transparent 10px, transparent 20px);
    }

    &:after {
        border: 1px solid $C_WHITE;
    }

    span {
        display: table;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 16px;
        border: 1px solid $C_WHITE;
        font-size: 2rem;
        text-align: center;
        background-color: $C_BLACK;
        max-width: 95%;
    }
}


/* ========================================
    main
        中見出し
======================================== */
.main-title {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    padding: 8px 10px;
    font-size: 1.6rem;
    overflow: hidden;

    &:before, &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        height: 1px;
        background-color: $C_WHITE;
        z-index: -1;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

/* ========================================
    sub
        子見出し
======================================== */
%sub-title {
    font-size: 1.5rem;
    color: $C_ORANGE;
}

.sub-title {
    @extend %sub-title;

    &__border {
        @extend %sub-title;
        padding-left: 8px;
        border-left: 4px solid $C_ORANGE;
    }

    &--small {
        font-size: 1.2rem;
    }
}