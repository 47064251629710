/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper

======================================== */

/* ========================================
    wrapper
======================================== */
.wrapper {
    overflow: hidden;
}

.inner {

}