/* ========================================
    
    parts
        付けるだけで動くスタイルのパーツ

    INDEX
        - element
        - layout
        - font
        - color
        - margin, padding
        - border
        - table
        - form

======================================== */

/* ========================================
    element
======================================== */
// XLサイズ以外で非表示
.xl-elem {
    @include mq-xl(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-xl(false) {
            display: none !important;
        }
    }
}

// Lサイズ以外で非表示
.l-elem {
    @include mq-l(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-l(false) {
            display: none !important;
        }
    }
}

// Mサイズ以外で非表示
.m-elem {
    @include mq-m(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-m(false) {
            display: none !important;
        }
    }
}

// Sサイズ以外で非表示
.s-elem {
    @include mq-s(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-s(false) {
            display: none !important;
        }
    }
}

// モバイル、タブレットサイズ以外で非表示
.mb-elem {
    @include mq-mb(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-mb(false) {
            display: none !important;
        }
    }
}

// PCサイズ以外で非表示
.pc-elem {
    @include mq-pc(false) {
        display: none !important;
    }

    &--inline {
        display: inline;
        
        @include mq-pc(false) {
            display: none !important;
        }
    }
}


/* ========================================
    layout
======================================== */
.al-c {
    text-align: center !important;
}

.al-r {
    text-align: right !important;
}

.al-l {
    text-align: left !important;
}

.fr {
    float: right !important;
}

.fl {
    float: left !important;
}

.cf {
    @include clearfix();
}


/* ========================================
    font
======================================== */
%x-small {
    font-size: 0.6em;
}

.x-small {
    @extend %x-small;
}

%small {
    font-size: 0.8em;
}

.small {
    @extend %small;
}

%large {
    font-size: 1.2em;
}

.large {
    @extend %large;
}

%x-large {
    font-size: 1.4em;
}

.x-large {
    @extend %x-large;
}

%bold {
    font-weight: bold;
}

.bold {
    @extend %bold;
}

%italic {
    font-style: italic;
}

.italic {
    @extend %italic;
}

%link {
    color: $C_LINK;
}

.link {
    @extend %link;
}


/* ========================================
    color
======================================== */
.c-white {
    color: $C_WHITE !important;
}

.c-gray {
    color: $C_GRAY !important;
}

.c-black {
    color: $C_BLACK !important;
}

.c-red {
    color: $C_RED !important;
}

.c-green {
    color: $C_GREEN !important;
}

.c-blue {
    color: $C_BLUE !important;
}

.c-yellow {
    color: $C_YELLOW !important;
}

.c-orange {
    color: $C_ORANGE !important;
}

.c-pink {
    color: $C_PINK !important;
}

.c-purple {
    color: $C_PURPLE !important;
}

.c-light_blue {
    color: $C_LIGHT_BLUE !important;
}

.c-ocher {
    color: $C_OCHER !important;
}



/* ========================================
    margin, padding
======================================== */
// 0〜120まで5px刻み
@for $i from 0 through 24 {
    $tmp: $i*5;
  
    // margin
    .mt-#{$tmp} {
        margin-top: #{$tmp}px !important;
    }
  
    .mr-#{$tmp} {
        margin-right: #{$tmp}px !important;
    }
  
    .mb-#{$tmp} {
        margin-bottom: #{$tmp}px !important;
    }
  
    .ml-#{$tmp} {
        margin-left: #{$tmp}px !important;
    }
  
    // padding
    .pt-#{$tmp} {
        padding-top: #{$tmp}px !important;
    }
  
    .pr-#{$tmp} {
        padding-right: #{$tmp}px !important;
    }
  
    .pb-#{$tmp} {
        padding-bottom: #{$tmp}px !important;
    }
  
    .pl-#{$tmp} {
        padding-left: #{$tmp}px !important;
    }
}


/* ========================================
    border
======================================== */
hr {
    &.solid {
        border: none;
        border-top: 1px solid;
    }

    &.dot {
        border: none;
        border-top: 1px dotted;
    }

    &.dash {
        border: none;
        border-top: 1px dashed;
    }
}

/* ========================================
    table
======================================== */
.table {
    border-collapse: collapse;
    border-spacing: 0;
    
    th, td {
        border: 1px solid $C_WHITE;
        padding: 8px 12px;
    }

    th {
        background-color: rgba($C_WHITE, 0.3);
    }
}


/* ========================================
    form
======================================== */
input[type="text"],
input[type="email"],
input[type="button"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="password"],
textarea {
	margin-top: 5px;
	margin-bottom: 5px;
    padding: 4px 8px;
    border: 1px solid $C_WHITE;
    border-radius: 0;
    background: rgba($C_WHITE,0.3);
    color: $C_WHITE;
    width: 100%;
	box-sizing: border-box;
	transition: 0.3s;
    
    &:focus {
        background-color: $C_WHITE;
        color: $C_BLACK;
    }
}


input[type="radio"],
input[type="checkbox"] {
    position: relative;
    display: inline-block;
    margin-top: 2px;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
    background-color: $C_BLACK;
    border: $C_WHITE solid 1px;
    appearance: none;
    transition: all 0.16s ease-out 0s;

    &:checked {
        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 12px;
            height: 12px;
            text-align: center;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

input[type="checkbox"] {
    &:checked {
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            left: 5px;
            width: 7px;
            height: 14px;
            transform: rotate(40deg);
            border-bottom: 3px solid $C_RED;
            border-right: 3px solid $C_RED;
        }
    }
}

input[type="radio"] {
    border-radius: 100%;

    &:checked {
        background-color: $C_RED;

        &:after {
            border-radius: 100%;
        }
    }
}

// 2個横並びの時
label + label {
    input[type="radio"],
    input[type="checkbox"] {
        margin-left: 16px;
    }
}


select {
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 32px;
    padding-bottom: 16px;
    background: rgba($C_WHITE, 0.3);
    border: 1px solid $C_WHITE;
    border-radius: 0;
	color: $C_WHITE;
    cursor: pointer;
    appearance: none;
    
    &:-ms-expand {
        display: none;
    }
}

.choise-select {
	position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 44%;
        right: 16px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid $C_WHITE;
        pointer-events: none;
    }

    select {
        width: 100%;
        appearance: none;
    }
}


.js-selectFile {
    .js-upload {
        display: none;
    }

    .choise-file-btn {
        @extend %normal-btn;
        @extend %btn-100;
    }
}