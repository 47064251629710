/* ========================================

    supportページで使う

    INDEX
        - mixin
        - main

======================================== */

/* ======================================
    main
====================================== */
.contents {
    margin: 20px 10px;
    font-size: 1.3rem;
}

#login {
    text-align: center;
    
    input[type=image] {
        max-width: 150px;
    }
}

/* ======================================
    enq
====================================== */
#tlForm{
    display: block;
    margin: 20px auto 10px;
    padding: 10px 0 0;
    border-top: #fff solid 1px;
    text-align: center;

    h1 {
        padding: 0 0 10px;
        background:url(/images/common/header_title_form.png) no-repeat 50% -24px;
        -webkit-background-size:370px auto;
        -moz-background-size:370px auto;
        -o-background-size:370px auto;
        -ms-background-size:370px auto;
        background-size:370px auto;
        height:130px;
        border-top:#fff solid 1px;
        overflow: hidden;
        text-indent:-10000px;
    }
}