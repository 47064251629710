/* ========================================

    support
        サポートのパーツ

    INDEX
        - mypage-table

======================================== */

/* ========================================
    mypage-table
======================================== */
.mypage-table {
    &__td{
        &--l {
            width: 45%;
        }

        &--r {
            width: 55%;
        }
    }
}