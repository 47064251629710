/* ========================================
    
    sns
        ソーシャルメディア系のスタイル

    INDEX
        - menu

======================================== */

/* ========================================
    
======================================== */
.sns {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 25px;
    top: 0;
    &__item {
        margin-right: 6.9vw;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }

    img,
    svg {
        height: 4.9vw;
        max-height: 23px;
        fill: white;
    }

    &__wrap {
        position: relative;
        padding: 0 10px;

        a {
            padding: 10px 0;
        }
    }
}

.info-pager i {
    opacity: 0.8;
}

