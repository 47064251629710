/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    news
======================================== */
#detailArea:not(img,p) {
    margin:20px 10px;
    font-size: 12px;
    padding: 0 0 8px;
    line-height:1.5em;
}

#detailArea {
    img{
        margin: 0 auto;
        padding: 0 0 5px 0;
        height: auto;
        vertical-align: top;
    }

    a:not(.btn) {
        color: $C_RED;
    }

    .btn {
        text-decoration:none;
        background:#e60012;
        color:#fff;
        padding:15px;
        text-align: center;
        position: relative;
        transition:.3s;
        margin:0px 20px;
        display: block;
        font-weight: bold;

        a {
            text-decoration:none;
            color:#fff !important;
            font-size:13px;
            display:block;
            font-weight:bold;
        }

        &::after {
            content:"";
            position:absolute;
            top:0;
            bottom:.1em;
            right:8%;
            margin:auto;
            width: .6em;
            height: .6em;
            border-top: .08em solid #fff;
            border-right: .08em solid #fff;
            transform: rotate(45deg);
            transition:.3s;
        }
    }
}

/* ========================================
    mailmagazin
======================================== */
.important_News {
    border: 1px $C_RED solid;
    padding: 10px;
    width: 97%;
    margin: 20px auto;
    color: $C_RED;

    p {
        font-size: 11px;
        line-height: 1.4em;

        &.important_News--title {
            font-size: 12px;
            font-weight: bold;
            color: $C_RED;
            margin-bottom: 3px;
        }
    }

    a {
        color: $C_RED;
    }
}


// 2021/04/02納品 app_news CSS
.app_news{display: flex;justify-content: space-between;margin:10px auto 10px;width:100%;background:rgba(255,255,255,.2);padding:10px 15px;}
.app_news .icon{width: 18%;background:none;}
.app_news .text{width: 82%;font-size:10px;color:rgba(255,255,255,.7);}
.app_news .text span{font-size:11px;font-weight:bold;display:block;color:#fff;margin-bottom:4px;}
.app_news img{width:38px;display:block;}