//INDEX
// - regist-icon-list
// - regist-list-box
// - career-list
// - credit-list


// ========================================
// - regist-icon-list
//========================================
.regist-icon-list {
    display: flex;
    justify-content: center;

    &__item {
        width: 24%;
        text-align: center;
        background-color: $C_WHITE;
        margin: 0 4px;
        font-size: 1.2rem;
        padding: 6px 0;

        a {
            color: $C_BLACK;
            text-decoration: none;
        }
    }
}




// ========================================
// - regist-list-box
//========================================
.regist-list-box {
    li{
        position: relative;
        margin-top: 16px;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 16px;
        border: 1px solid $C_WHITE;
        box-shadow: 0 0 0 0px $C_WHITE, 0 0 0 2px $C_BLACK, 0 0 0 4px $C_WHITE;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 16px;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 8px;
            border-top: 1px solid $C_WHITE;
            border-right: 1px solid $C_WHITE;
            vertical-align: middle;
            transform: rotate(135deg);
          }

        a {
            display: block;
            padding: 8px;
            text-decoration: none;
        }
    }
}

.num_hd {
    font-size: 1.2em;
    font-weight: bold;
    padding-right: 5px;
}





// ========================================
// - career-list
//========================================
.career-list {
	&__item {
		margin-bottom: 10px;
        
        .a-logo {
            width: 80.5px;
            vertical-align: middle;
        }
    }

	img[src$="docomo.png"] {
		width: 78px;
	}
}




// ========================================
// - credit-list
//========================================
.credit-list {
	display: flex;
    align-items: center;
    justify-content: center;
    background-color: $C_WHITE;

	&__item {
		margin-right: 5px;
	}

	img {
		vertical-align: middle;

		&[src$="logo_visa.png"] {
			width: 48px;
		}

		&[src$="logo_mastercard.png"] {
			width: 43px;
		}

		&[scr$="logo_jcb.png"] {
			width: 33px;
		}

		&[src$="logo_amex.jpg"] {
			width: 46.5px;
        }
        
        &[src$="logo_diners.png"] {
			width: 52px;
		}
	}
}


// ========================================
// - regist-table
//========================================
form {
    table:not([class]) {
        margin-bottom: 40px;
        width: 100%;
        text-align: left;

        th, td {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }
    }
}