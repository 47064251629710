/* ========================================
    機能
        アコーディオンとして使う際に最低限必要な機能
======================================== */
.js-accordion-body {
	display: none;

	&.is-open {
		display: block;
	}
}

.js-accordion-title {
	cursor: pointer;
}


/* ========================================
    デザイン
        見た目の関するところ
======================================== */
.ac-menu {
    // タイトル
    dt {
        position: relative;
        cursor: pointer;
        margin-top: 16px;
        margin-left: 4px;
        margin-right: 4px;
        padding-top: 8px;
        padding-left: 8px;
        padding-right: 24px;
        padding-bottom: 8px;
        letter-spacing: 0.15em;
        border: 1px solid $C_WHITE;
        box-shadow: 0 0 0 0px $C_WHITE, 0 0 0 2px $C_BLACK, 0 0 0 4px $C_WHITE;
    
        &.js-open,
        &.fixed-open {
            color: $C_BLACK;
            background-color: $C_WHITE;
    
            &:before, &:after {
                background: $C_BLACK;
            }
            
            &:after {
                transform: rotate(0deg)
            }
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 1px;
            background-color: $C_WHITE;
            transition: 0.3s;
        }
    
        &:after {
            transform: rotate(90deg)
        }
    }

    // 本文
    dd {
        margin-top: 1px;
        padding: 12px;
        border: 1px solid $C_WHITE;
    }
}