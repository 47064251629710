/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 16px;
    color: $C_BLACK !important;
    text-decoration: none !important;
    text-align: center;
    font-weight: bold;
    background-color: lighten($C_ORANGE, 15%);
    background-image: radial-gradient(#000 4px, transparent 5px), url(#{$IMG}common/imege_bk_net_2x.png);
    background-size: 24px 24px, 13px;
    background-position: right, center;
    background-repeat: no-repeat, repeat;
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 32px 8px;
    color: $C_BLACK !important;
    text-decoration: none !important;
    text-align: center;
    font-weight: bold;
    // background-color: #cad1d3;
    // background-image: repeating-linear-gradient(transparent, transparent 80%, #a8d0cf 80%, #a8d0cf 90%, transparent 90%), url(#{$IMG}common/sr_pattern.png);
    background-color: #85d9f1;
    background-image: url(#{$IMG}common/imege_bk_net_2x.png);
    background-size: 13px;
    background-repeat: repeat;
    background-position: center;
}

// 幅いっぱいのサイズ
%btn-100 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80%;
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
        よく使うボタン
======================================== */
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;
}

.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.btn-special-100 {
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %special-btn;
    @extend %btn-50;
}

/* ========================================
    more
======================================== */
.btn-more {
    position: relative;
    display: table;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px 16px;
    border: 1px solid $C_WHITE;
    text-decoration: none !important;
    text-align: center;
    background-color: $C_BLACK;
}