// ========================================
//  aem scss templates
//      ver 2.0.3
//		既存サイトに後から追加される用のscssファイル。影響範囲を狭めてある
// ======================================== 
@charset 'UTF-8';

* {
    box-sizing: border-box;
}


/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
// @import "common/reset";
// @import "common/normalize";
@import "common/base";
@import "common/parts";
// @import "common/animation";


/* ========================================
    modules
        外部プラグインなどで使うもの
======================================== */
@import "modules/accordion";
// @import "modules/bxslider_reset";
// @import "modules/card";
// @import "modules/colorbox_reset";
// @import "modules/photoswipe";
// @import "modules/share_buttons";

/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
@import "components/common";
// @import "components/header";
// @import "components/footer";
@import "components/sns";
// @import "components/nav";
@import "components/support";
// @import "components/pager";
@import "components/title";
@import "components/btn";
// @import "components/list";
// @import "components/movie";
// @import "components/post-entry";

// タブレット以上の場合のみホバーを適応
@media screen and (min-width: $BP_M) {
    @import "components/hover";
}


/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index"; // topだけじゃなくてサイト全体のピンポイントなCSSかいてます
@import "pages/regist";
@import "pages/support";